export const ENDPOINTS = {
  LOGIN: "users/login",
  USER_API: "users/list",
  SIGNUP_API: "companies/createMerchant",
  EMAIL_VERIFICATION: "users/verify",
  COUNTRY_LIST_API: "country/list",
  ADD_CUSTOMER_API: "companies/createclient",
  ADD_CATEGORY_API: "svcctgy/add",
  GET_SERVICE_API: "svcctgy/list",
  ADD_SUB_CATEGORY_API: "svcctgyitems/add",
  UPDATE_CATEGODY_API: "svcctgy/update",
  DELETE_CATEGORY_API: "svcctgy/remove",
  UPDATE_SUB_CATEGODY_API: "svcctgyitems/update",
  UPDATE_SUB_CATEGORY_STATUS_API: "/svcctgyitems/update/status",
  UPDATE_CATEGORY_STATUS_API: "svcctgy/update/status",
  DELETE_SUB_CATEGORY_API: "svcctgyitems/remove",
  ADD_EMPLOYEE_API: "users/upsert/create-employee",
  ADD_APPOINTMENT: "appointments/upsert",
  GET_APPOINTMENT: "appointments/list",
  APPOINTMENT_TIME_BLOCK: "schedule/list",
  ASSIGN_SERVICE_TO_STAFF_API: "users/create/employee-service",
  SERVICE_STAFF_RELATION_LIST_API: "employee-service-relation/list",
  UPDATE_EMPLOYEE_STATUS: "users/status",
  DELETE_STAFF_EMPLOYEE_RELATION: "employee-service-relation/delete",
  SET_UP_BUSSINESS_API: "companies-preference/create",
  GET_BUSSINESS_SETUP_API: "companies-preference/list",
  APPOINTMENT_COUNT_API: "appointments/date-filter",
  CLIENT_EMAIL_VERIFICATION: "users/verify-email",
  DELETE_EMPLOYEE: "users/remove",
  UPDATE_ATTENDENCE: "schedule/update-status",
  VERIFY_USER_REFRESH: "users/getByEmail",
  ADD_EMPLOYEE_SHIFT: "schedule/create",
  DELETE_EMPLOYEE_SHIFT: "schedule/remove",
  ADD_COMMISSION_API: "employee-commission/create",
  GET_COMMISSION_API: "employee-commission/employee-list",
  SEND_PAYMENT_LINK: "appointment/transaction/v1/generate/gateway/link",
  GET_PAYMENT_TRANSACTION: "appointment/transaction/list",
  GET_MULTI_STORE_API:'ownerBusiness/store/list',
  ADD_STORE_API:'companies/management',
  EDIT_STORE_API:'companies/management',
  BUSSINESS_COUNT_API:'companies/statistics',
  ADD_STORE_LOCATION:"companies/manageAddress",
   UPDATE_CUSTOMER_API:"companies/createclient"
};
